const importRetry: any = async (
	importFn: any,
	retries = 2,
	interval = 1000
) => {
	try {
		return await importFn();
	} catch (error: any) {
		if (retries) {
			await wait(interval);
			return importRetry(importFn, retries - 1, interval);
		} else {
			throw new Error(error);
		}
	}
};
export default importRetry;
function wait(ms: any) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
