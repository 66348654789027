import { createSlice } from "@reduxjs/toolkit";

export type IModal = {
	data: any;
};

const initialState: IModal = {
	data: {}
};

const modalSlice = createSlice({
	name: "modal",
	initialState,
	reducers: {
		setModalData: (state: any, action: any) => {
			state.data = action?.payload;
		},
		resetModalData: (state: any) => {
			state.data = {};
		}
	}
});

export const getModal = (state: any) => {
	return state.modal;
};

export const modalReducer = modalSlice.reducer;
export const { setModalData, resetModalData } = modalSlice.actions;
