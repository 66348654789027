import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "./Screenloader.scss";
const Screenloader = () => {
	return (
		<div className="screen_loader">
			<AiOutlineLoading3Quarters className="loading__icon" />
		</div>
	);
};

export default Screenloader;
