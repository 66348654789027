import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const AUTH_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_AUTH_URL
    : process.env.REACT_APP_PROD_AUTH_URL;

// const AUTH_URL = process.env.REACT_APP_PROD_AUTH_URL;

type salarySlip = {
  email: string | undefined;
  monthlyTotal: number | undefined;
  basic: number | undefined;
  hra: number | undefined;
  lta: number | undefined;
  misc: number | undefined;
  // workingdays: number | undefined;
  Unpaidleave: number | undefined;
  extradays: number | undefined;
  checkbox: boolean;
  dat: string;
  date_of_joining: string | undefined;
  designation: string | undefined;
  pan_no: string | undefined;
  reimbursement: number;
  totalSalary: number;
  reference_no: number | undefined;
};

type monthType = {
  month: string;
  year: string;
};

export const salarySlipApi = createApi({
  reducerPath: 'salarySlip',
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_URL,
    prepareHeaders: (headers: any) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: [
    'generateSalarySlip',
    'exportSalarySlip',
    'updateEmployeeSalary',
    'fetchSalarySlip',
    'salaryData',
  ],
  endpoints: (builder) => ({
    generateSalarySlipNow: builder.mutation<any, salarySlip>({
      query: (salarySlip) => ({
        url: '/generateSalarySlipNow',
        method: 'POST',
        body: salarySlip,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['exportSalarySlip'],
    }),
    exportSalarySlip: builder.mutation<any, monthType>({
      query: (month) => ({
        url: '/exportToExcel',
        method: 'POST',
        body: month,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['generateSalarySlip'],
    }),
    updateEmployeeSalary: builder.mutation<any, salarySlip>({
      query: (salarySlip) => ({
        url: '/updateSalaryDetails',
        method: 'POST',
        body: salarySlip,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['updateEmployeeSalary'],
    }),
    getSalarySlip: builder.query({
      query: (month) => ({
        url: `/salarySlip?month=${month}`,
        method: 'GET',
        // body: salarySlip,
        credentials: 'include',
        // headers: {
        // 	"Content-Type": "application/json"
        // }
      }),
      transformResponse: (response: { data: any }, meta, arg) => response.data,
      providesTags: ['fetchSalarySlip'],
    }),
    generateEmployeeSlip: builder.query<
      any,
      { employeeId: string; date: string }
    >({
      query: ({ employeeId, date }) => ({
        url: `/generateSlip/${employeeId}/${date}`,
      }),
      providesTags: ['salaryData'],
    }),
    sendSalarySlip: builder.mutation<
      any,
      { employeeId: string; file: File; monthYear: string }
    >({
      query: ({ employeeId, file, monthYear }) => {
        const formData = new FormData();
        formData.append('salarySlip', file);
        formData.append('monthYear', monthYear);

        return {
          url: `/salary-slip/${employeeId}`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['salaryData'],
    }),
  }),
});

export const {
  useGenerateSalarySlipNowMutation,
  useExportSalarySlipMutation,
  useUpdateEmployeeSalaryMutation,
  useGetSalarySlipQuery,
  useSendSalarySlipMutation,
  useLazyGenerateEmployeeSlipQuery,
} = salarySlipApi;
