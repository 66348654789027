import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// const AUTH_URL = process.env.REACT_APP_PROD_AUTH_URL;
const AUTH_URL =
	process.env.NODE_ENV === "development"
		? process.env.REACT_APP_DEV_AUTH_URL
		: process.env.REACT_APP_PROD_AUTH_URL;

export const docsApi = createApi({
	reducerPath: "docsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: AUTH_URL,
		prepareHeaders: (headers: any) => {
			headers.set("Authorization", `Bearer ${localStorage.getItem("token")}`);
			return headers;
		}
	}),
	tagTypes: ["uploadCompanyDocs", "getDocs", "deleteComapnyDoc"],
	endpoints: (builder) => ({
		uploadCompanyDoc: builder.mutation<any, { data: any }>({
			query: ({ data }) => ({
				url: "/upload",
				method: "POST",
				body: data,
				credentials: "include"
			}),
			invalidatesTags: ["getDocs", "deleteComapnyDoc"]
		}),
		getCompanyDocs: builder.query<any, void>({
			query: () => ({
				url: "/companyPolicies"
			}),
			providesTags: ["getDocs", "deleteComapnyDoc"]
		}),
		deleteCompanyDoc: builder.mutation<
			any,
			{ fileKey: string | undefined | null }
		>({
			query: ({ fileKey }) => ({
				url: `/deleteDoc/?id=${fileKey}`,
				method: "DELETE",
				body: fileKey,
				credentials: "include"
			}),
			invalidatesTags: ["getDocs", "uploadCompanyDocs"]
		})
	})
});

export const {
	useGetCompanyDocsQuery,
	useUploadCompanyDocMutation,
	useDeleteCompanyDocMutation
} = docsApi;
