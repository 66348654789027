import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './apis/authApi';
import { attendanceApi } from './apis/attendanceApi';

import { requestApi } from './apis/requestApi';
import { salarySlipApi } from './apis/salarySlipApi';
import { docsApi } from './apis/companyDocApi';
import { modalReducer } from './modal';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [attendanceApi.reducerPath]: attendanceApi.reducer,
    [requestApi.reducerPath]: requestApi.reducer,
    [salarySlipApi.reducerPath]: salarySlipApi.reducer,
    [docsApi.reducerPath]: docsApi.reducer,
    modal: modalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      attendanceApi.middleware,
      requestApi.middleware,
      salarySlipApi.middleware,
      docsApi.middleware
    ),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
