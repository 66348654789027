import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type leaveResponsetype = {
  employee_id: string;
  type?: string;
  date: string;
  message: string;
  status: string;
  resolved_by?: {};
};
interface SubmitLeaveApplicationPayload {
  employee_id: string;
  leaveType: string;
  days: number;
  recipientID: string[];
  description: string;
}

type dateType = {
  date: string;
  isHalfDay: boolean;
  session: number;
};

type leaveType = {
  user: {
    _id: string | undefined;
  };
  data: dateType[];
  category: string | undefined;
  receivers: { receiver_id: string | undefined }[];
  message: string;
};
type uninformedleaveType = {
  user: {
    _id: string | undefined;
  };
  data: string[];
  category: string | undefined;
  message: string;
};

const AUTH_URL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_AUTH_URL
    : process.env.REACT_APP_PROD_AUTH_URL;

// const AUTH_URL = process.env.REACT_APP_PROD_AUTH_URL;

export const requestApi = createApi({
  reducerPath: 'requestApi',
  baseQuery: fetchBaseQuery({
    baseUrl: AUTH_URL,
    prepareHeaders: (headers: any) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: [
    'requestForLeave',
    'getAllRequests',
    'getAllEmployeeRequests',
    'uninformedLeaveRequest',
    'LeaveData',
  ],
  endpoints: (builder) => ({
    leaveRequest: builder.mutation<any, any>({
      query: (leaveData) => ({
        url: '/leave',
        method: 'POST',
        body: leaveData,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: [
        'getAllRequests',
        'getAllEmployeeRequests',
        'uninformedLeaveRequest',
      ],
    }),
    uninformedLeaveRequest: builder.mutation<
      leaveResponsetype,
      uninformedleaveType
    >({
      query: (leaveData) => ({
        url: '/uninformedLeave',
        method: 'POST',
        body: leaveData,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: [
        'getAllRequests',
        'getAllEmployeeRequests',
        'requestForLeave',
      ],
    }),
    getAllRequest: builder.query<any, { _id: string | undefined }>({
      query: ({ _id }) => ({
        url: `/getRequests/${_id}`,
      }),
      providesTags: ['getAllRequests'],
    }),
    getEmployeeRequest: builder.query<any, { _id: string | undefined }>({
      query: ({ _id }) => ({
        url: `/my-requests/${_id}`,
      }),
      providesTags: ['getAllEmployeeRequests'],
    }),
    resolveRequest: builder.mutation<
      any,
      { id?: string; userId?: string; status?: string; msg?: string }
    >({
      query: ({ id, userId, status, msg }) => ({
        url: '/resolve',
        method: 'POST',
        body: {
          id,
          userId,
          status,
          msg,
        },
        credentials: 'include',
      }),
      invalidatesTags: ['getAllRequests'],
    }),
    getSchedule: builder.query<any, string>({
      query: (id) => `/schedule/${id}`,
    }),
    resolveSchedule: builder.mutation<
      any,
      { id?: string; userId?: string; status?: string; msg?: string }
    >({
      query: ({ id, userId, status, msg }) => ({
        url: '/resolve',
        method: 'POST',
        body: {
          id,
          userId,
          status,
          msg,
        },
        credentials: 'include',
      }),
      invalidatesTags: ['getAllRequests'],
    }),
    leaveAdd: builder.mutation<any, Record<string, any>>({
      query: (body) => ({
        url: '/hr/apply',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAllRequests', 'getAllEmployeeRequests'],
    }),
    deleteRequest: builder.mutation<any, { _id: string | null }>({
      query: ({ _id }) => ({
        url: '/delete',
        method: 'POST',
        body: {
          _id,
        },
      }),
      invalidatesTags: ['getAllRequests', 'getAllEmployeeRequests'],
    }),
    getLeaveData: builder.query<any, void>({
      query: () => '/status',
      providesTags: ['LeaveData'],
    }),
    getStatusById: builder.query<any, string>({
      query: (statusId) => `status/${statusId}`,
    }),
    submitLeaveApplication: builder.mutation<any, SubmitLeaveApplicationPayload>({
      query: (formData) => ({
        url: '/slack-leave',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: formData,
      }),
      invalidatesTags: ['LeaveData'], // This should be adjusted according to your caching strategy
    }),
    updateAvailability: builder.mutation<any, { slack_id: string; _id: string }>({
      query: ({ slack_id, _id }) => ({
        url: `/slack/notify/${slack_id}`,
        method: 'POST',
        body: {
          id: _id,
        },
      }),
      invalidatesTags: ['getAllRequests', 'getAllEmployeeRequests'],
    }),
  }),
});

export const {
  useLeaveRequestMutation,
  useUninformedLeaveRequestMutation,
  useGetAllRequestQuery,
  useGetEmployeeRequestQuery,
  useSubmitLeaveApplicationMutation,
  useResolveRequestMutation,
  useGetScheduleQuery,
  useDeleteRequestMutation,
  useGetStatusByIdQuery,
  useGetLeaveDataQuery,
  useLeaveAddMutation,useUpdateAvailabilityMutation
} = requestApi;
